class Popup {
    constructor() {
        this.init();
    }
 
    init() {
       
                  // Initially hide all popup contents
        document.querySelectorAll('.popup-content').forEach(popup => popup.style.display = 'none');
        document.addEventListener('click', function(event) {
             const modalback = document.getElementById("backdrop");
             const getBackdrop = document.querySelectorAll(".sitecore-popup.open");
             if (getBackdrop.length > 0) {
                 modalback.style.display = 'block';
             }
         });
         window.addEventListener('hashchange', () => {    
             openPopup();
             });
  
         //Add click event to each popup link , store "data-item-name" in hidden field
         document.querySelectorAll('.popup-container-active').forEach(link => {
             link.addEventListener('click', (event) => {                                
                 openPopup();
                 let answer = document.querySelectorAll("[data-sc-field-name='source_item_name']");
                 if(answer !== '' || answer !== null){
                     document.querySelector("[data-sc-field-name='source_item_name']").value = link.getAttribute('data-item-name');
                 }
             })
     })
  
         document.querySelectorAll('.modal__cancel').forEach(closeButton => {
             closeButton.addEventListener('click', () => {
                 closeButton.closest('.sitecore-popup').classList.remove('open');
                 document.getElementById('backdrop').classList.remove('open');
                 const modalback = document.getElementById("backdrop");
                 modalback.style.display = 'none';
             });
         });
  
          function openPopup() {
             const popupId =window.location.hash.substring(1);
             const popupData = document.getElementById(popupId);
             const modalback = document.getElementById("backdrop");
              if(!window.location.hash.includes("formSection"))
              {
                    if(popupData)
                    {
                    modalback.style.display = 'block';
                    const scrollPosition = window.scrollY;
                    popupData.querySelector('.sitecore-popup') && popupData.querySelector('.sitecore-popup').classList.add('open');
                    document.getElementById('backdrop').classList.add('open');
                    history.replaceState(null, null, window.location.pathname + window.location.search);
                    window.scrollTo(0, scrollPosition);
                    }
               } 
            }
        }
    }
 
export default Popup;