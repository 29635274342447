class CustomDropdown {
    constructor(dropdown, anchor = false) {
        this.dropdownRef = dropdown;
        this.dropdown = document.querySelector(dropdown);
        this.dropdownValue = document.querySelector(`${this.dropdownRef} .dropdown-selected`);
        this.dropdownItems = document.querySelectorAll(`${this.dropdownRef} .dropdown-item`);
        if (this.dropdown) {
            this.initDropdown();
        }
        this.anchor = anchor;
    }

    initDropdown() {
        this.dropdown.addEventListener("click", this.setupEventListeners.bind(this, this.dropdown));
        document.addEventListener("click", this.eventOutside.bind(this));
        this.dropdownItems.forEach(item => item.addEventListener("click", this.handleDropdownItem.bind(this, item)))
    }

    setupEventListeners(el, item) {
        if(el.classList.contains('active')) {
            el.classList.remove('active');    
        } else {
            el.classList.add('active');
        }

    }

    eventOutside(e) {
        let curObj = e.target;
        if (!curObj.closest(this.dropdownRef)) {
            if (this.dropdown.classList.contains('active')) {
                this.dropdown.classList.remove("active");
            }
        } else {
            if (curObj.closest(`${this.dropdownRef} .dropdown-item`)) {
                this.dropdown.classList.remove("active");
            }
        }
    }

    handleDropdownItem(el, current) {
        if (this.anchor == false) {
            let itemValue = el.getAttribute('data-value');
            let itemflag = el.getAttribute('data-flag');
            let val = this.dropdownValue.querySelector(`.selected-value`);
            let flag = this.dropdownValue.querySelector(`.selected-flag > img`);
            val.innerHTML = itemValue;
            if (itemflag) {
                flag.src = itemflag;
            }
        }
    }
}

export default CustomDropdown