class PropertySlider {
    constructor(gallery) {
        this.gallery = gallery
        this.slider = this.gallery.querySelectorAll('.gallery-slider')[0];
        this.thumbs = this.gallery.querySelectorAll('.gallery-thumbs')[0];
        this.swiperWrapper = this.slider.querySelectorAll('.swiper-wrapper')[0];
        this.swiper = null;
        this.thumbswiper = null;
    this.initSwiper()
     
    }
  
    initSwiper() {
        
      if (
        this.slider &&
        this.slider.children &&
        this.slider.children.length > 0
      ) {
        this.swiper = new Swiper(this.slider, {

          slidesPerView: 1,
            centeredSlides: true,
            loop: true,
            loopedSlides: this.swiperWrapper.children.length,
            navigation: {
                nextEl: '.swiper-button-next',
                prevEl: '.swiper-button-prev',
            },
        });
        this.thumbswiper = new Swiper (this.thumbs, {
            slidesPerView: 'auto',
            spaceBetween: 10,
            centeredSlides: true,
            loop: false,
            slideToClickedSlide: true,
        });
        this.swiper.controller.control = this.thumbswiper;
        this.thumbswiper.controller.control = this.swiper;
      }
    }
  
  }
  
  export default PropertySlider;
  