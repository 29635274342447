class SwiperFilter {
    constructor(filters) {
        this.cardsFilters = document.querySelectorAll(filters);
        this.initializeFilters();
    }

    initializeFilters() {
        this.cardsFilters.forEach((item) =>
            item.addEventListener("click", (e) => this.filterAction(item))
        );
    }

    filterAction(filterAnchor) {
        this.cardsFilters.forEach((item) => item.classList.remove("active"));
        let slider = document.getElementById(
            `${this.cardsFilters[0].parentElement.getAttribute("data-filterSlideRef")}`
        );
        let filter = filterAnchor.getAttribute("data-ref");
        filterAnchor.classList.add("active");
        if (slider) {
            let slides = slider.querySelectorAll(".swiper-wrapper > div");
            slides.forEach((item) => {
                if (filter == "all") {
                    this.updateSlideClasses(item, "swiper-slide");
                } else {
                    if (item.getAttribute("data-filter") == filter) {
                        this.updateSlideClasses(item, "swiper-slide");
                    } else {
                        this.updateSlideClasses(item, "non-swiper-slide");
                    }
                }
            });
            this.updateSwiper(slider);
        }
    }

    updateSlideClasses(item, className) {
        item.removeAttribute("class");
        item.classList.add(className);
    }

    updateSwiper(slider) {
        let swiper = slider.swiper;
        swiper.updateSize();
        swiper.updateSlides();
        swiper.updateProgress();
        swiper.updateSlidesClasses();
        let slideCount = swiper.slides.length;
        if (slideCount > 2) {
            if(slideCount % 2 === 0) {
                swiper.slideTo(slideCount / 2);
            } else {
                swiper.slideTo(slideCount / 2) - .5;
            }
        } else {
            swiper.slideTo(0);
        }
    }
}

export default SwiperFilter