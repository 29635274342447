import { responsiveHandlerRef } from "../../utils/constant";

class ResponsiveHandler {
    constructor() {
        this.init();
    }

    init() {
        window.addEventListener('resize', () => this.landscapeModeOff());
        window.addEventListener('load', () => this.landscapeModeOff());
    }

    landscapeModeOff() {
        const portraitWarning = document.getElementById(responsiveHandlerRef.targetID);
        if (window.innerHeight < 450 && window.innerWidth > 480 && window.innerWidth < 820) {
            if (portraitWarning) {
                portraitWarning.style.display = "flex";
            }
        } else {
            if (portraitWarning) {
                portraitWarning.style.display = "none";
            }
        }
    }
}

export default ResponsiveHandler