class SearchHandler {
    constructor(handler) {
      this.searchForm = document.querySelector(handler);
      this.init();
    }
    init() {
      if (this.searchForm) {
        this.searchForm.addEventListener("submit", this.submitForm.bind(this));
      }
    }
    submitForm(e) {
          e.preventDefault();
   
          let searchInput = this.searchForm.getElementsByTagName("input");
          if(searchInput && searchInput.length>0)
          {
            let searchtext= searchInput[0].value;
            const url = this.searchForm.getAttribute("data-url");
            let encodedSearchInput = encodeURIComponent(searchtext);
            window.location.href = url + "?key=" + encodedSearchInput;
          }
         
        };
      
    
  }
   
  export default SearchHandler;