import { deviceWidth } from "../../utils/constant";

class FileRackSlider {
  constructor(container, element) {
    this.screenWidth = window.innerWidth;
    this.container = document.querySelectorAll(container);
    this.handlerTarget = element;
    this.init();
  }

  init() {
    window.addEventListener("load", () => this.activateSection());
  }

  activateSection() {
    if (this.container) {
      setTimeout(() => {
        this.container.forEach((item) => {
          const sectionWrap = item.querySelector(".satisfy-wrapper");
          let resize = 14;
          let absheight = 16;
          if (this.screenWidth > deviceWidth.desktop) {
            resize = parseInt(this.screenWidth / 120);
            absheight = 28;
          }
          let handlers = item.querySelectorAll(this.handlerTarget);
          if (handlers.length > 1) {
            const calculatedHeight =
              sectionWrap.offsetHeight - absheight * resize;
            item.style.height = calculatedHeight / resize + "em";
            handlers.forEach((item) =>
              item.addEventListener(
                "click",
                this.handleTargetClick.bind(this, item, handlers)
              )
            );
          } else {
            sectionWrap.style.position = "relative";
          }
        });
      }, 3000);
    }
  }

  handleTargetClick(el, allHandlers, current) {
    current.stopPropagation();
    allHandlers.forEach((item) => {
      if (item === el) {
        item.classList.add("active");
      } else {
        item.classList.remove("active");
      }
    });
  }
}

export default FileRackSlider;
