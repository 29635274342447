class HomeFinanceCalc {
    constructor(input1, input2, displayVal) {
        this.amountInput = document.getElementById(input1);
        this.monthsInput = document.getElementById(input2);
        this.resultElement = document.getElementById(displayVal);

        this.initCalc();
    }

    initCalc() {
        if (this.amountInput) {
            this.calculateTotalCost();
            this.amountInput.addEventListener('input', (e) => this.calculateTotalCost());
            this.monthsInput.addEventListener('input', (e) => this.calculateTotalCost());
        }
    }

    calculateTotalCost() {
        const amount = +this.amountInput.value;
        const months = +this.monthsInput.value;
        let profitRate = (+this.resultElement.getAttribute('data-rate')) || 0;
        let a = (+profitRate / 100 / 12) || 1;
        let b = months / 1.835 / months;
        let c = amount / months;
        let totalCost = ((a * amount) * b) + c;
        this.resultElement.textContent = `${totalCost.toLocaleString()}`;
    }
}

export default HomeFinanceCalc