import {
  branchLocatorRef
} from "../../utils/constant";

class BranchLocator {
    constructor(mapDivId) {
    this.mapData = window.mapData;
    this.ATMBranches;
    this.SourceLocation = {
      lat: 0,
      lng: 0
    };
    this.DestinationLocation = {
      lat: 0,
      lng: 0
    };
    this.filters = [];
    this.BRLocation = "";
    this.SelectedTime = "";
    this.ResultBranches;
    this.CenterCoordinates = window.defaultLatLong;
    this.defaultRegion = window.defaultRegion;
    this.ATMListByEmirates;
    this.tourStops = [];
    this.ErresMessage = window.errorMessage;
    this.markers = [];
    this.uniqueLocations = {};
    this.uniqueBranchesData = [];
    this.map;
    this.infoWindow;
    this.mapDivId = mapDivId;
    this.listContainer = document.getElementById(
      branchLocatorRef.listLocationsId
    );
    this.locationField = document.getElementById(
      branchLocatorRef.selectLocationId
    );
    this.timingField = document.getElementById(
      branchLocatorRef.selectTimingId
    );
    this.SelectedTime = this.timingField && this.timingField.value;
    this.init();
  }

  async init() {
    if (this.locationField) {
      this.attachEventListeners();
    }
  }

  initMap() {
    let mapElement =  document.getElementById(this.mapDivId);
    if (mapElement) {
      this.map = new google.maps.Map(mapElement, {
          zoom: 8,
          center: this.CenterCoordinates,
        }
      );

      this.infoWindow = new google.maps.InfoWindow();
      this.filterEmiratesATM();
    }
  }

  createUpdateMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
    this.tourStops.forEach(([position, title, data], i) => {
      let marker = new google.maps.Marker({
        position,
        map: this.map,
        title: title,
        content: data,
        optimized: false,
        markid: `marker${i}`,
        icon: branchLocatorRef.pinImg,
      });
      marker.addListener("click", () => {
        this.infoWindow.close();
        this.infoWindow.setContent(data);
        this.infoWindow.open(marker.getMap(), marker);
        this.map.setCenter(marker.getPosition());
        this.map.setZoom(8);
      });
      this.map.setZoom(8);
      this.markers.push(marker);
    });
    this.map.setCenter(this.markers[0].getPosition());
  }

  showMarker(index) {
    const selectedMarker = this.markers[index];
    if (selectedMarker) {
      this.infoWindow.setContent(this.markers[index].content);
      this.infoWindow.open(this.map, this.markers[index]);
      this.map.setCenter(selectedMarker.getPosition());
      this.map.setZoom(12);
    }
  }

  populateSelectData() {
    this.ATMBranches.forEach((branch) => {
      const location = branch.Location;

      if (!this.uniqueLocations[location]) {
        this.uniqueLocations[location] = true;
        this.uniqueBranchesData.push(branch);
      }
    });
    for (let i = 0; i < this.uniqueBranchesData.length; i++) {
      this.locationField.innerHTML += `<option value="${this.uniqueBranchesData[i].Location}" data-center="{ lat: ${this.uniqueBranchesData[i].Lattitude}, lng: ${this.uniqueBranchesData[i].Longitude} }">${this.uniqueBranchesData[i].Location}</option>`;
    }
  }

  attachEventListeners() {
    document
      .getElementById(branchLocatorRef.searchInputId)
      .addEventListener("input", this.onSearchFilter.bind(this));

    document
      .querySelectorAll(`input[name="${branchLocatorRef.checkBoxName}"]`)
      .forEach((checkbox) => {
        checkbox.addEventListener(
          "change",
          this.handleFacilityChange.bind(this, checkbox)
        );
      });

    this.locationField.addEventListener(
      "change",
      this.handleLocationChange.bind(this)
    );
    
    this.timingField.addEventListener(
      "change",
      this.handleTimingChange.bind(this)
    );

    document.addEventListener(
      "DOMContentLoaded",
      this.domContentLoaded.bind(this)
    );
  }

  onSearchFilter(event) {
    let filter = event.target.value.toUpperCase();
    let li = this.listContainer.getElementsByTagName("li");
    for (let i = 0; i < li.length; i++) {
      let a = li[i].getElementsByClassName("BRATMList_anchor")[0];
      if (a.innerHTML.toUpperCase().indexOf(filter) > -1) {
        li[i].style.display = "";
      } else {
        li[i].style.display = "none";
      }
    }
  }

  handleFacilityChange(checkbox, event) {
    if(event.target.checked)
    {
      let index = this.filters.indexOf(checkbox.value);
      if (index == -1) {
        this.filters.push(checkbox.value);
      }
     
    }
    else{
      let index = this.filters.indexOf(checkbox.value);
      if (index !== -1) {
        this.filters.splice(index, 1);
        }
    }
   
    this.displayFilteredData();
  }

  handleLocationChange(e) {
    let branchFacilities = document.querySelectorAll(
      `input[name="${branchLocatorRef.checkBoxName}"]`
    );

    // branchFacilities.forEach((checkbox) => {
    //   checkbox.checked = false;
    //   this.filters[checkbox.value] = "No";
    // });

    this.BRLocation = this.locationField.value;
    this.SelectedTime = this.timingField && this.timingField.value;
    this.filterEmiratesATM();
  }

  handleTimingChange(e) {
    let branchFacilities = document.querySelectorAll(
      `input[name="${branchLocatorRef.checkBoxName}"]`
    );

    // branchFacilities.forEach((checkbox) => {
    //   checkbox.checked = false;
    //   this.filters[checkbox.value] = "No";
    // });

    this.SelectedTime = this.timingField && this.timingField.value;
    this.filterEmiratesATM();
  }
  domContentLoaded() {
    this.setLanguageBasedErrorMessage();
    this.populateSelectData();
    if (this.locationField) {
      this.locationField.value = this.defaultRegion;
      this.BRLocation = this.locationField.value;
    }

    this.ATMListByEmirates = this.ATMBranches;
    this.initMap();
    this.getLocation();
  }

  setLanguageBasedErrorMessage() {
      this.ErresMessage = window.errorMessage;
      this.ATMBranches = this.mapData;
  }

  async getLocation() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        this.showPosition.bind(this),
        this.geoLocationError.bind(this)
      );
    } else {
      console.log(branchLocatorRef.geoLocationMessage);
    }
  }

  async geoCodeCoordinates(coordinates) {
    let geocodeUrl = document.getElementById(this.mapDivId).getAttribute('data-geocodeUrl');
    let geocodeApiUrl = `${geocodeUrl}?lat=${coordinates.lat}&long=${coordinates.lng}`;
    try {
      let response = await fetch(geocodeApiUrl);
      let jsonResp = await response.json();
      
      if (jsonResp?.status == "OK" && jsonResp?.results?.length > 0) {
        let locationData = jsonResp.results;
        let locality = null;
        locationData.forEach((locationChunk) => {
          locationChunk.address_components.forEach((locationSubChunk) => {
            if (
              locationSubChunk.types.includes("locality") &&
              locationSubChunk.types.includes("political")
            ) {
              locality = locationSubChunk.short_name;
              this.BRLocation = this.defaultRegion;
              this.uniqueBranchesData.forEach((uniqueBranch) => {
                if (uniqueBranch.Location == locality) {
                  this.BRLocation = uniqueBranch.Location;
                }
              });
            }
          });
        });

        this.filterEmiratesATM();
        return locality;
      } else {
        return null;
      }
    } catch (error) {
      console.error("geoCodeCoordinates--Error Message  : " + error.message);
      return null;
    }
  }

  geoLocationError(error) {
    console.error("Geolocation error: " + error.message);
  }

  async showPosition(position) {
    this.SourceLocation = {
      lat: position.coords.latitude,
      lng: position.coords.longitude,
    };
    let currentRegion = await this.geoCodeCoordinates(this.SourceLocation);
    if (currentRegion !== undefined && currentRegion != null) {
      if (this.locationField) {
        let allowedRegions = [];
        for (let i = 0; i < this.locationField.options.length; i++) {
          allowedRegions.push(this.locationField.options[i].value);
        }
        if (allowedRegions.includes(currentRegion)) {
          this.locationField.value = currentRegion;
        } else {
          this.locationField.value = this.defaultRegion;
        }
      }
    }
  }

  filterEmiratesATM() {
    this.ATMListByEmirates = this.ATMBranches.filter(
      (value) => ((value.Location == this.BRLocation) && (value.Timing.indexOf(this.SelectedTime)!==-1))
    );
   
    this.displayFilteredData();
  }


  displayFilteredData() {
    this.listContainer.innerHTML = "";
    this.tourStops = [];

    let branchFacilities = document.querySelectorAll(
      `input[name="${branchLocatorRef.checkBoxName}"]`
    );
    let resultBranch = [];
    let notAllSelected = false;
    Object.values(this.filters).forEach((val) => {
      if (val === "True") {
        notAllSelected = true;
      }
    });
    if(this.filters.length>0)
    {
      notAllSelected = true;
    }

    if (notAllSelected) {
      resultBranch = this.ATMListByEmirates.filter((value, key) => {
        if (
         this.checkFacilities(value,this.filters)
        ) {
          return value;
        }
      });
    } else {
      resultBranch = this.ATMListByEmirates;
    }
    this.createFilterData(resultBranch);
    this.createUpdateMarkers();
  }

  createList(index, title, locationIcon) {
    const listItem = document.createElement("li");
    listItem.className = "BRATMList";
    const anchor = document.createElement("div");
    anchor.className = "BRATMList_anchor";
    anchor.addEventListener("click", (e) => {
      this.showMarker(index);
      let anchors = this.listContainer.querySelectorAll(".BRATMList_anchor");
      anchors.forEach((item) => {
        item.classList.remove("active");
      });
      anchor.classList.add("active");
    });
    const leftDiv = document.createElement("div");
    leftDiv.className = "left-side";
    const rightDiv = document.createElement("div");
    rightDiv.className = "icon-right";
    const icon = document.createElement("img");
    icon.src = "/assets/svg/right-arrow.svg";
    icon.alt = "";
    rightDiv.appendChild(icon);

    const titleIcon = document.createElement("div");
    titleIcon.className = "icon";
    const iconTitleImg = document.createElement("img");
    iconTitleImg.src = locationIcon ? locationIcon : "/assets/svg/bank-icon.svg";
    iconTitleImg.alt = "Bank";
    titleIcon.appendChild(iconTitleImg);
    const titleName = document.createElement("div");
    titleName.className = "contentWrapper";
    const titlehead = document.createElement("h5");
    titlehead.className = "h5";
    titlehead.textContent = title;
    titleName.appendChild(titlehead);

    leftDiv.appendChild(titleIcon);
    leftDiv.appendChild(titleName);

    anchor.appendChild(leftDiv);
    anchor.appendChild(rightDiv);

    listItem.appendChild(anchor);

    this.listContainer.appendChild(listItem);
    return listItem;
  }

  createFilterData(dataArray) {
    dataArray.forEach((item, i) => {
      this.tourStops.push([{
          lat: parseFloat(item.Lattitude),
          lng: parseFloat(item.Longitude),
        },
        item.DisplayTitle,
        `<div class=al-branch><h4 class=h4>${
          item.DisplayTitle
        }</h4><ul><li><div class=icon><img src=/assets/svg/pin-map-white.svg class=svg-convert alt=""></div><div class=content><p class=p>${
          item.DisplayTitle
        }</p></div></li><li><div class=icon><img src=/assets/svg/call-bank.svg class=svg-convert alt=""></div><div class=content><p class=p>${
          item.PhoneNumber
        }</p></div></li><li><div class=icon><img src=/assets/svg/timer-clock.svg class=svg-convert alt=""></div><div class=content><p class=p>${
          item.Timings
        }</p></div></li><li><div class=icon><img src=/assets/svg/atm-ccdn.svg class=svg-convert alt=""></div><div class=content><p class=p>${
          item.Notes
        }</p></div></li></ul><div class=anchor-wrapper><a href=${
          window.popuplink1+"?api=1&query="+item.Lattitude+","+item.Longitude
        } target=_blank class="arrow-anchor directionBtn"><div class=content>${
             window.popupBtnText1
        }</div><div class=imgWrapper><i class=icon-arrow></i></div></a><a target=_blank href=${
          window.popuplink2
        } class="arrow-anchor primary border-0"><div class=content>${
          window.popupBtnText2
        }</div><div class=imgWrapper><i class=icon-arrow></i></div></a></div></div>`,
      ]);
      this.createList(i, item.DisplayTitle,item.LocationIconImage);
    });
  }

  checkFacilities = (location, selectedFacilities) => {
    return location.Facilities.some(item => selectedFacilities.indexOf(item) !== -1);
  };

}

export default BranchLocator;