class LanguageSwitcher {
    constructor(handler) {
        this.handler = document.querySelectorAll(handler);;
       this.init();
    }

    init() {
        this.handler.forEach(item => item.addEventListener("click", (e) => this.switchURL(e)));
    }

    switchURL(e) {
        e.stopPropagation();
        let currentURL = window.location.href;
        let pathname= window.location.pathname;
        let newPath = '';
       
        let currentLanguageCode= e.currentTarget.getAttribute('data-languagecode');
        let alternatelanguageCode= e.currentTarget.getAttribute('data-alternatelanguagecode');
        if(!(currentLanguageCode && alternatelanguageCode))
        {
            return;
        }
        if(pathname==="/")
        {
            let newURL = currentURL.replace(/\/$/, alternatelanguageCode);
            window.location.href = newURL;
        }
        else
        {
        if (pathname.includes(currentLanguageCode)) {
            newPath = pathname.replace(currentLanguageCode, alternatelanguageCode);
        } else {
            newPath = alternatelanguageCode + pathname;
        }
           
        let newURL = currentURL.replace(pathname, newPath);
        window.location.href = newURL;
        }
    }

}

export default LanguageSwitcher