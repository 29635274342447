import {
    deviceWidth
} from "../../utils/constant";

class FontResizerController {
    constructor() {
        this.init();
    }
    init() {
        window.addEventListener('load', () => window.innerWidth > deviceWidth.desktop && this.resizeFont());
        window.addEventListener('resize', () => window.innerWidth > deviceWidth.desktop && !(window.innerWidth < deviceWidth.mac14 && window.innerWidth > deviceWidth.mac13 && window.innerHeight > 900) && this.resizeFont());
    }

    resizeFont() {
        const perc = parseInt(window.innerWidth) / 120;
        document.body.style.fontSize = perc + 'px';
    }
}

export default FontResizerController;