import {
  moreLessRef
} from "../../utils/constant";

class MoreLessToggle {
  constructor() {
    this.elements = document.querySelectorAll(moreLessRef.targetClass);
    this.init();
  }

  init() {
    this.elements.forEach(item => {
      item.textContent = item.dataset.more;
      item.addEventListener("click", this.toggle.bind(this));
    })
  }

  toggle(evt) {
    evt.preventDefault();
    const currentEvtObj = evt.target;

    if (!currentEvtObj.classList.contains(moreLessRef.activeClass)) {
      this.showLess(currentEvtObj);
    } else {
      this.showMore(currentEvtObj);
    }
  }

  showLess(element) {
    element.textContent = element.dataset.less;
    element.previousElementSibling.classList.remove(moreLessRef.clampClass);
    element.classList.add(moreLessRef.activeClass);
    element.closest(moreLessRef.closestParent).classList.remove(moreLessRef.parentAlignmentClass);
  }

  showMore(element) {
    element.textContent = element.dataset.more;
    element.previousElementSibling.classList.add(moreLessRef.clampClass);
    element.classList.remove(moreLessRef.activeClass);
    element.closest(moreLessRef.closestParent).classList.add(moreLessRef.parentAlignmentClass);
  }
}


export default MoreLessToggle;