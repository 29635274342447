import { promotionFilterRef } from "../../utils/constant";

class PromotionFilter {
  constructor(action, month, year, search, reset) {
    this.data = {
      selectedAction: action,
      selectedMonth: month,
      selectedYear: year,
      selectedSearch: search,
      selectedReset: reset,
    };
    this.init();
  }

  init() {
    this.action = document.getElementById(this.data.selectedAction);
    this.month = document.getElementById(this.data.selectedMonth);
    this.year = document.getElementById(this.data.selectedYear);
    this.search = document.getElementById(this.data.selectedSearch);
    this.reset = document.getElementById(this.data.selectedReset);
    // Add event listeners to the select elements, search input, and clear button
    if(window.innerWidth <= 768){
      this.action && this.action.addEventListener("click", this.toggleFilter.bind(this));
    }
    this.month &&
      this.month.addEventListener("change", this.filterPromotions.bind(this));
    this.year &&
      this.year.addEventListener("change", this.filterPromotions.bind(this));
    this.search &&
      this.search.addEventListener("input", this.filterPromotions.bind(this));
      this.search && this.search.addEventListener(
      "keydown",
      function (event) {
        if (event.keyCode === 13) {
          event.preventDefault();
        }
      }.bind(this)
    );
    this.reset &&
      this.reset.addEventListener("click", this.resetFilters.bind(this));
  }

  filterPromotions() {
    let promotionCards = document.querySelectorAll(promotionFilterRef.targetCard);
    let searchVal = this.search.value;
    let month = +this.month.value;
    let year = +this.year.value;
    let nodata = true;
    promotionCards.forEach(function (card) {
      let cardDate = new Date(card.getAttribute(promotionFilterRef.dateAttr));
      let cardMonth = cardDate.getMonth() + 1;
      let cardYear = cardDate.getFullYear();
      let cardTitle = card
        .getAttribute(promotionFilterRef.titleAttr)
        .toLowerCase();

      card.classList.add("d-none");
      if (
        cardTitle.includes(searchVal.toLowerCase())
      ) {
        if (
          (month === 0 || month === cardMonth) &&
          (year === 0 || year === cardYear)
        ) {
          card.classList.remove("d-none");
          nodata = false;
        }
      }
      let nodataLayer = document.querySelector(promotionFilterRef.nodataWrap);
      if (nodata) {
        nodataLayer.style.display = "block";
      } else {
        nodataLayer.style.display = "none";
      }
    });
  }

  resetFilters() {
    this.month.value = 0;
    this.year.value = 0;
    this.search.value = "";

    // Trigger filter promotions function to reset the display
    this.filterPromotions();
  }

  toggleFilter() {
    const toggleWrapper = document.getElementsByClassName("custom-wrapper");
    if (toggleWrapper[0].style.maxHeight === "unset")
      toggleWrapper[0].style.maxHeight = 0;
    else {
      toggleWrapper[0].style.maxHeight = "unset";
    }
  }
}

export default PromotionFilter;
