class NavbarToggler {
    constructor(togglerClass, mobileHeaderClass) {
        this.toggler = document.querySelector(togglerClass);
        this.mobileHeader = document.querySelector(mobileHeaderClass);
        this.htmlTag = document.querySelector('html');

        if (this.toggler) {
            this.toggler.addEventListener("click", this.toggleNavbar.bind(this));
        }
    }

    toggleNavbar() {
        if (this.mobileHeader && this.htmlTag) {
            this.mobileHeader.classList.toggle('open');
            this.htmlTag.classList.toggle('overflow-hidden');
        }
    }
}
export default NavbarToggler