import {
  deviceWidth
} from "../../utils/constant";

class HeaderMenuController {
  constructor(menuSelector, contentSelector, cardSelector, cardListSelector, headerSelector, stickyOffset) {
    this.menuItems = document.querySelectorAll(menuSelector);
    this.contentItems = document.querySelectorAll(contentSelector);
    this.cardItems = document.querySelectorAll(cardSelector);
    this.spans = [...document.body.querySelectorAll(cardListSelector)];
    this.header = document.querySelector(headerSelector);
    this.stickyOffset = stickyOffset || 0;

    this.setActive = this.setActive.bind(this);
    this.setActive1 = this.setActive1.bind(this);
    this.removeActive = this.removeActive.bind(this);
    this.handleScroll = this.handleScroll.bind(this);

    // Check if window width is greater than iPad width before attaching event listeners
    if (window.innerWidth > deviceWidth.ipad) {
      if (this.menuItems) {
        this.menuItems.forEach((item) => item.addEventListener("mouseover", (e) => this.setActive(item)));
      }

      if (this.contentItems) {
        this.contentItems.forEach((contentItem) => {
          contentItem.style.display = "none";
        });
      }

      if (this.cardItems && this.spans) {
        this.cardItems.forEach((cardItem) => {
          cardItem.style.display = "none";
        });

        this.spans.forEach((el) => {
          el.addEventListener("mouseover", (e) => this.setActive1(el));
          el.addEventListener("mouseleave", () => this.removeActive(el));
        });
      }

      window.addEventListener("scroll", this.handleScroll);
      this.handleScroll(); // Initial check
    }
  }

  setActive(el) {
    [
      ...el.parentElement.parentElement.parentElement.parentElement.children,
    ].forEach((sib) => {
      sib.querySelectorAll(".menu-list").forEach((itemsib) => {
        itemsib.classList.remove("active");
      });
    });

    this.contentItems.forEach((contentsib) => {
      contentsib.style.display = "none";
    });

    this.cardItems.forEach((cardItem) => {
      cardItem.style.display = "none";
    });

    el.classList.add("active");
    let activetabcontentid = el.getAttribute("data-src");
    let tabc = document.getElementById(activetabcontentid);
    if (tabc) {
      tabc.style.display = "block";
    }
  }

  setActive1(el) {
    [
      ...el.parentElement.parentElement.parentElement.parentElement.children,
    ].forEach((sib) => {
      sib.querySelectorAll(".card-list").forEach((itemsib) => {
        itemsib.classList.remove("active");
      });
    });

    this.cardItems.forEach((cardItem) => {
      cardItem.style.display = "none";
    });

    el.classList.add("active");
    let activetabcontentid = el.getAttribute("data-id");
    let tabc = document.getElementById(activetabcontentid);
    if (tabc) {
      tabc.style.display = "block";
    }
  }

  removeActive(el) {
    el.classList.remove("active");

    this.cardItems.forEach((cardItem) => {
      cardItem.style.display = "none";
    });
  }
  handleScroll() {
    if (window.pageYOffset > this.stickyOffset) {
      this.header && this.header.classList.add("sticky");
    } else {
      this.header && this.header.classList.remove("sticky");
    }
  }
}

export default HeaderMenuController