class FilterModal {
  constructor(filterModal, filtertitleMobile) {
    this.filter = document.querySelectorAll(filtertitleMobile);
    this.filterModal = document.querySelectorAll(filterModal);
    this.init();
  }
  init() {
    if (this.filter) {
      this.filter.forEach(item => {
        if (item instanceof HTMLElement) {
            item.addEventListener("click", this.showModal.bind(this));
          } else {
            console.error("Item is not a valid HTMLElement.");
          }
      });
    }
  }

  showModal(){
    if (this.filterModal.length) {
        this.filterModal.forEach(filteritem => {
            if(filteritem && filteritem.classList.contains('filteropen')){
                filteritem.classList.remove('filteropen')
                filteritem.classList.add('filterClose')
            }else{
                filteritem.classList.add('filteropen')
                filteritem.classList.remove('filterClose')
            }
        });
      }
      if (this.filter.length) {
        this.filter.forEach(filteritem => {
          if (filteritem.classList.contains('filter-icon')) {
            filteritem.classList.remove('filter-icon');
            filteritem.classList.add('filter-closeicon');
          } else {
            filteritem.classList.remove('filter-closeicon');
            filteritem.classList.add('filter-icon');
          }
        });
      }
  }
}
export default FilterModal;
