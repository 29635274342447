class CarFinanceCalc {
    constructor(input1, input2, displayVal, profitRate) {
        this.amountInput = document.getElementById(input1);
        this.monthsInput = document.getElementById(input2);
        this.resultElement = document.getElementById(displayVal);
        this.initCalc();
    }

    initCalc() {
        if (this.amountInput) {
            this.calculateTotalCost();
            this.amountInput.addEventListener('input', (e) => this.calculateTotalCost());
            this.monthsInput.addEventListener('input', (e) => this.calculateTotalCost());
        }
    }

    calculateTotalCost() {
        const amount = +this.amountInput.value;
        const months = +this.monthsInput.value;
        let profitRate = (+this.resultElement.getAttribute('data-rate'));
        let a = (profitRate || 1) * amount * months / 12;
        const totalCost = ((amount + a) / months).toLocaleString();

        this.resultElement.textContent = `${totalCost}`;
    }
}

export default CarFinanceCalc