import { filterByCatRef } from "../../utils/constant";

class FilterCardsbyCat {
  constructor(handler, card, form, title) {
    this.handlers = document.querySelectorAll(handler);
    this.cards = document.querySelectorAll(card);
    this.formReset = document.getElementById(form);
    this.cardTitle = document.getElementById(title);
    this.init();
  }

  init() {
    this.handlers.forEach((item) =>
      item.addEventListener("pointerdown", (e) => this.setFilterAction(item))
    );
  }

  setFilterAction(handler) {
    if (this.formReset) {
      this.formReset.reset();
    }
    const selectedCategory = handler.querySelector(`a[${filterByCatRef.attr}]`);
    let category = selectedCategory.dataset.ref;
    let catergoryName = selectedCategory.innerText;

    this.cardTitle.innerHTML = catergoryName;

    this.handlers.forEach((slide) =>
      slide.classList.remove(filterByCatRef.targetActive)
    );
    handler.classList.add(filterByCatRef.targetActive);
    let arrLength = [];
    if (category == filterByCatRef.allAlias) {
      this.cards.forEach((card) => {
        arrLength.push(card);
        card.classList.remove(filterByCatRef.hideClass);
        card.classList.remove(filterByCatRef.horLayoutClass);
      });
    } else {
      this.cards.forEach((item) => {
        item.classList.add(filterByCatRef.horLayoutClass);
        if (item.dataset.category === category) {
          arrLength.push(item);
          item.classList.remove(filterByCatRef.hideClass);
        } else {
          item.classList.add(filterByCatRef.hideClass);
        }
      });
    }
    let noData = document.querySelector(filterByCatRef.nodataWrap);
    if (arrLength.length < 1) {
      noData.style.display = "block";
    } else {
      noData.style.display = "none";
    }
  }
}

export default FilterCardsbyCat;
