class CounterAnimator {
    constructor(selector, className, options = { rootMargin: "0px", threshold: 0.2 }) {
        this.elements = document.querySelectorAll(selector);
        this.className = className;
        this.options = options;

        this.onIntersection = this.onIntersection.bind(this);
        this.observer = new IntersectionObserver(this.onIntersection, this.options);

        this.observeElements();
    }

    observeElements() {
        this.elements.forEach((element) => {
            this.observer.observe(element);
        });
    }

    onIntersection(entries, observer) {
        entries.forEach((entry) => {
            if (entry.isIntersecting) {
                const counterElement = entry.target.querySelector(".counter");
                const endValue = parseInt(counterElement.innerText.replace(/[^\d]/g, ""), 10);
                const duration = parseInt(counterElement.getAttribute('data-duration'), 10) || 1000; // Default to 1000ms if not specified

                entry.target.classList.add(this.className);
                this.animateCounter(counterElement, endValue, duration);
                observer.unobserve(entry.target); // Stop observing once the class is added and animation starts
            }
        });
    }

    animateCounter(element, endValue, duration) {
        let startValue = 0;
        const increment = endValue / (duration / 100);
        const interval = setInterval(() => {
            startValue += increment;
            if (startValue >= endValue) {
                startValue = endValue;
                clearInterval(interval);
            }
            element.innerText = Math.floor(startValue);
        }, 100);
    }
}

export default CounterAnimator;