import {
  deviceWidth
} from "../../utils/constant";
class AccordianHandler {
  constructor(element) {
    this.handlers = document.querySelectorAll(element);

    if (this.handlers.length > 0) {
      this.initEventListeners();
    }
  }

  initEventListeners() {
    this.handlers.forEach((item) => {
      if (item.getAttribute('data-disableTillIpad') == "true") {
        if (window.innerWidth < deviceWidth.mobile) {
          item.addEventListener("click", this.handleTargetClick.bind(this, item));
        }
      } else if (item.getAttribute('data-disableTillDesktop') == "true") {
        if (window.innerWidth < deviceWidth.desktop) {
          item.addEventListener("click", this.handleTargetClick.bind(this, item));
        }
      } else {
        item.addEventListener("click", this.handleTargetClick.bind(this, item));
      }
    });

    if (!this.handlers[0].getAttribute('data-sibling')) {
      document.addEventListener("click", this.handleClickOutside.bind(this));
    }
  }

  handleTargetClick(target, event) {
    event.stopPropagation(); // Prevent document click event from being triggered
    this.activateCurrent(target);
  }

  handleClickOutside() {
    this.deactivateAll();
  }

  activateCurrent(target) {
    this.handlers.forEach((item) => {
      const isOpen = item.classList.contains("active");

      item.classList.toggle("active", item === target);

      let hasSibling = target.getAttribute("data-sibling");

      if (hasSibling == "true") {
        if (!isOpen && item.classList.contains("active")) {
          item.nextElementSibling.style.maxHeight = item.nextElementSibling.scrollHeight + "px";
          item.nextElementSibling.classList.add("opened");
        } else {
          item.nextElementSibling.style.maxHeight = null;
          item.nextElementSibling.classList.remove("opened");
          item.classList.remove("active");
        }
      }
    });
  }

  deactivateAll() {
    this.handlers.forEach((target) => {
      target.classList.remove("active");
    });
  }
}


export default AccordianHandler