import { svgConverterRef } from "../../utils/constant";

class SVGConverter {
    constructor() {
        this.convertSVGs();
    }

    convertSVGs() {
        const svgs = document.querySelectorAll(`img${svgConverterRef.targetClass}`);
        svgs.forEach((el) => {
            const imgID = el.getAttribute("id");
            const imgClass = el.getAttribute("class");
            const imgURL = el.getAttribute("src");

            fetch(imgURL)
                .then((response) => response.text())
                .then((data) => {
                    const parser = new DOMParser();
                    const xmlDoc = parser.parseFromString(data, "text/html");
                    let svg = xmlDoc.querySelector("svg");

                    if (typeof imgID !== "undefined") {
                        svg.setAttribute("id", imgID);
                    }

                    if (typeof imgClass !== "undefined") {
                        svg.setAttribute("class", imgClass + " " + svgConverterRef.afterConvertClass);
                    }

                    svg.removeAttribute("xmlns:a");

                    el.parentNode.replaceChild(svg, el);
                });
        });
    }
}

export default SVGConverter