import SVGConverter from "./module/SVGConverter";
import AccordianHandler from "./module/AccordianHandler";
import ShowHide from "./module/ShowHide";
import SwiperFilter from "./module/SwiperFilter";
import HeaderMenuController from "./module/HeaderMenuController";
import SwiperSlider from "./module/SwiperSlider";
import FontResizerController from "./module/FontResizerController";
import PageLoader from "./module/PageLoader";
import NavbarToggler from "./module/NavbarToggler";
import CustomDropdown from "./module/CustomDropdown";
import ResponsiveHandler from "./module/ResponsiveHandler";
import SliderNavigation from "./module/SliderNavigation";
import MoreLessToggle from "./module/MoreLessToggle";
import PromotionCardManager from "./module/PromotionCardManager";
import PhoneInputHandler from "./module/PhoneInputHandler";
import RangeSlider from "./module/RangeSlider";
import HomeFinanceCalc from "./module/HomeFinanceCalc";
import CarFinanceCalc from "./module/CarFinanceCalc";
import LanguageSwithcer from "./module/LanguageSwitcher";
import PromotionFilter from "./module/PromotionFilter";
import FilterCardsbyCat from "./module/FilterCardsbyCat";
import FilterByForm from "./module/FilterByForm";
import BranchLocator from "./module/BranchLocator";
import SearchHandler from "./module/SearchHandler";
import FileRackSlider from "./module/FileRackSlider";
import FilterModal from "./module/FilterModal";
import PropertySlider from "./module/PropertySlider";
import Popup from "./module/Popup";
import Leadership from "./module/Leadership";
import CounterAnimator from "./module/CounterAnimator";


// Generic Module for apply on all Pages

// Page loader Module use for page load
const pageLoader = new PageLoader();

// SVG Converter Module use for convert SVG Images to code
const svgConverterInstance = new SVGConverter();

// Font Resizer use for Dekstop responsive screens auto adjust the size of the element with font size.
const desktopResponsive = new FontResizerController();

// ResponsiveHandler use disbale Landscape Mobile mode
const mobileLandscapeOff = new ResponsiveHandler();

// Switch URL Arabic to English
const swithchUrl = new LanguageSwithcer(".switch-language");
const swithchUrlMobile = new LanguageSwithcer(
  "#languageSwitcher .dropdown-item"
);

// Use as per Requirement

// Module use For Accordian File Rack.
const fileRack = new FileRackSlider(
  ".investor-relations",
  ".satisfactory-card"
); // Target Section, Target Element

// Show Hide Module
const showHideSearchBar = new ShowHide(".search-btn", ".search-close"); // Target Button, Close Button

// AccordianHandler use for Accrodian
const footerMobileMenu = new AccordianHandler(".dropdown-footer"); // Target Button or Anchor
const mobileBurgerMenu = new AccordianHandler(".dropdown-toggle"); // Target Button or Anchor
const subMenu = new AccordianHandler(".submenu"); // Target Button or Anchor

const dropdownBtn = new AccordianHandler(".dropdown-btn");

// CustomDropdown Module use for dropdown selection.
const regionalDropdown = new CustomDropdown("#regionSwitcher", true); // Target Dropdown Container
const languageDropdown = new CustomDropdown("#languageSwitcher"); // Target Dropdown Container
const loginBtnDropdown = new CustomDropdown("#loginAnchor", true); // Target Dropdown Container only clickable not selected
const loginBtnDropdown1 = new CustomDropdown("#loginAnchorMobile", true); // Target Dropdown Container only clickable not selected

// Generic Slider Module Create slider which contains the class swiper.
const swiperSliders = document.querySelectorAll(".swiper");
swiperSliders.forEach((item) => new SwiperSlider(item));

//Propert Slider
const propertySlider = document.querySelectorAll(".gallery");
propertySlider.forEach((item) => new PropertySlider(item));


// Swiper Filter use for filter swiper slides
const swiperFilter = new SwiperFilter(".slider-filters > li"); // Swiper Filter Tabs with Parent Class or ID

// Header Controlelr Modile Menu
const navbarToggler = new NavbarToggler(
  ".mobile-header .navbar-toggler",
  ".mobile-header"
);

// Header Controlelr Modile Menu
const headerMenuController = new HeaderMenuController(
  ".menu-list",
  ".single-wrapper",
  ".singleVisa-cards",
  ".card-list",
  ".desktop-header"
);

// Range Slider
let rangeBars = document.querySelectorAll(".range-slider"); // Targeted Slider Class
rangeBars.forEach((item) => new RangeSlider(item));

// Finance CalculatorhomeFinance
const homeFinance = new HomeFinanceCalc(
  "slider_input_home_amount",
  "slider_input_home_months",
  "montly-payment-home"
);
const carFinance = new CarFinanceCalc(
  "slider_input_car_amount",
  "slider_input_car_months",
  "montly-payment-car"
);
const homeFinanceDuplicate = new HomeFinanceCalc(
  "slider_input_home1_amount",
  "slider_input_home1_months",
  "montly-payment-home1"
);

// Inner Page Module

// Slider Navigation use for Swithcing to specific Slide
const slideAnchorHandler = new SliderNavigation(
  ".key-benefits__anchors li",
  "key-benefits"
); // Tab or Anchor, Swiper Slider ID

// Show Hide Module
const showHideForm = new ShowHide(
  "#accountFormBtn",
  "#closeForm",
  "mobile",
  true
); // target Button or Anchor, close button, device only (optional), stopPropagation
const filterPopup = new ShowHide(
  "#openFilterBtn",
  "#closeFilter",
  "",
  false,
  "#backdrop"
);

// More or Less Content Module
const showHidenText = new MoreLessToggle();

// Phone Code Number
const phoneCode = new PhoneInputHandler();

// Promotion Card Hover
const promotionCardManager = new PromotionCardManager(".list--modifier");

// Promotion Card Filter
const promotionFilters = new PromotionFilter(
  "filterAction",
  "monthSelect",
  "yearSelect",
  "searchInput",
  "resetButton"
);

// Adib Card Filters
const filterHandler = new FilterCardsbyCat(
  ".adib-cards__category .swiper-slide",
  ".covered-wrapper",
  "filterForm",
  "card-title"
); // handlers class, cards class
const filterFormCard = new FilterByForm(
  ".covered-wrapper",
  "filterForm",
  ".adib-cards__category .swiper-slide-active"
); // handlers class, cards class, active Category

// Branch Locator
const branchLocator = new BranchLocator("map"); // map id, english data, ARABIC DATA
window.initMap = branchLocator.initMap.bind(branchLocator);

// Search bar
const searchHandler = new SearchHandler(
  "#search-form"
); //redirects page after searching

const searchHandlerMobile = new SearchHandler(
  "#search-form-mobile"
); 
const filterModalInstance = new FilterModal('.filter-modal', '.filter-title-mobile');

const popup = new Popup("modals");

const leadership = new Leadership("backdrop");

const counterAnim = new CounterAnimator(".milestone-card", "in-view", { rootMargin: "0px", threshold: 0.5 });

