import { rangeSliderRef } from "../../utils/constant"

class RangeSlider {
    constructor(rangeSliderElement) {
        this.bar = rangeSliderElement.querySelector(rangeSliderRef.targetClass);
        this.filler = rangeSliderElement.querySelector(rangeSliderRef.targetSliderFiller);
        this.valContainer = rangeSliderElement.querySelector(rangeSliderRef.targetValueContain);
        if(this.bar) {

            this.minVal = +this.bar.getAttribute('min');
            this.maxVal = +this.bar.getAttribute('max');
            this.updateBar(this.bar.value);
            this.bar.addEventListener('input', () => this.updateBar(this.bar.value));
        }
    }

    updateBar(val) {
        if (this.valContainer) {
            let valueDiv = this.valContainer.querySelector(rangeSliderRef.valueContain);
            valueDiv.innerHTML = (+val).toLocaleString();
            const fillerVal = `${(((+val) - this.minVal) * 100) / (this.maxVal - this.minVal)}%`;
            if(fillerVal){

                let checkLang = document.querySelector("html").getAttribute('lang')
                if (checkLang == "ar") {
                    this.valContainer.style.right = fillerVal;
                } else {
                    this.valContainer.style.left = fillerVal;
                }
                this.filler.style.width = fillerVal;
            }
        }
    }
}

export default RangeSlider;