import { pageLoaderRef } from "../../utils/constant";


class PageLoader {
    constructor() {
        this.init();
    }

    init() {
        window.addEventListener('load', () => this.pageLoader());
    }

    pageLoader() {
        setTimeout(() => {
            const loaderFirst = document.querySelector(pageLoaderRef.targetClass);

            if (loaderFirst) {
                loaderFirst.style.display = 'none';
                document.querySelector("html").classList.remove(pageLoaderRef.htmlClass);
            }
        }, 300);
    }
}

export default PageLoader