class Leadership {
    constructor(backdrop) {
        this.modalBackdrop = document.getElementById(backdrop);
        this.init();
    }
 
    init() {
        // Store reference to the current instance
        const self = this;
        // Listen for clicks to check for active modals
        document.addEventListener('click', function(event) {
            const modalback = document.getElementById("backdrop");
            const getBackdrop = document.querySelectorAll('[class="modal show"]');
            if (getBackdrop.length > 0) {
                modalback.style.display = 'block';
            }
            const openModal = document.querySelector('.modal.show[style*="display: block;"]');
            if (openModal) {
                self.handleModal(openModal.id, modalback);
            }
        });
    }

    handleModal(modalId, modalback) {
        // Get the modal element by ID
        const modalElement = document.getElementById(modalId);
        // Attach event listener for when the modal is hidden
        modalElement.addEventListener('hidden.bs.modal', function () {
            modalback.style.display = 'none';
        });
    }
}

export default Leadership;
