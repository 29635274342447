class PromotionCardManager {
    constructor(selector) {
        this.promotionCards = document.querySelectorAll(selector);
        this.init();
    }

    init() {
        if (this.promotionCards.length > 0) {
            this.promotionCards.forEach((item) => {
                item.addEventListener("mouseover", (e) => this.setActivePromotion(item));
                item.addEventListener("mouseleave", (e) => this.removeActivePromotion(item));
            });
        }
    }

    setActivePromotion(target) {
        target.classList.add("extend");
        let filtered = [];
        this.promotionCards.forEach(item => {
            if(!(item.classList.contains('d-none'))) {
                filtered.push(item);
            }
        })
        filtered.forEach((item, i) => {
            if (item.classList.contains("extend")) {
                if (i % 2 === 0) {
                    filtered[i + 1].classList.add('squeeze');
                } else {
                    filtered[i-1].classList.add('squeeze');
                }
            }
        });

    }

    removeActivePromotion(target) {
        target.classList.remove("extend");

        this.promotionCards.forEach((item) => {
            item.classList.remove('squeeze');
        });
    }
}

export default PromotionCardManager