class SlideAnchorHandler {
    constructor(selector, containerId) {
        this.slidesAnchors = document.querySelectorAll(selector);
        this.containerId = containerId;

        if (this.slidesAnchors.length > 0) {
            this.attachEventListeners();
        }
    }

    attachEventListeners() {
        this.slidesAnchors.forEach(item => {
            item.addEventListener("click", this.handleAnchorClick.bind(this));
        });
    }

    handleAnchorClick(e) {
        const index = e.target.parentElement.getAttribute('data-index');
        const container = document.getElementById(this.containerId);
        if (container && container.swiper) {
            container.swiper.slideTo(index, 2000);
        }
    }
}


export default SlideAnchorHandler