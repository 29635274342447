import {
  phoneHandlerRef
} from "../../utils/constant";

class PhoneInputHandler {
  constructor() {
    this.init();
  }

  init() {
    if (document.querySelector(phoneHandlerRef.targetClass)) {
      window.addEventListener("load", () => {
        const inputs = document.querySelectorAll(phoneHandlerRef.targetClass);
        inputs.forEach((input) => {
          input.addEventListener("countrychange", function () {
              var inputElements = document.querySelectorAll('.phoneiti');
              inputElements?.forEach(function(inputElement) {
                  var fieldName = inputElement.getAttribute('data-sc-field-name');
                  var elements = inputElement.parentElement.querySelectorAll('.iti__selected-dial-code');
                  elements?.forEach(function(element) {
                      var inputVal = element.innerHTML;
                      var countryCode = `countryCode${fieldName}`;
                      var hiddenInput = document.querySelector(`[data-sc-field-name="${countryCode}"]`);
                      if (hiddenInput) {
                          hiddenInput.value = inputVal;
                      } 
                  });
              });
          });
          this.setupPhoneInput(input);
          
        });
      });
    }
  }
 
  phoneFilledValue(evt) {
    let hiddenPhoneField= evt.target.querySelector(phoneHandlerRef.hiddenElementTargetClass);
    var inputVal = $('.iti__selected-dial-code').text();
    hiddenPhoneField.val(inputVal);
  }

  setupPhoneInput(inputElement) {
    if (inputElement.classList.contains("onlyuae")) {
        window.intlTelInput(inputElement, {
          hiddenInput: "full_phone",
          onlyCountries: ["ae"],
          initialCountry: "auto",
          separateDialCode: true,
          geoIpLookup: this.geoIpLookup.bind(this),
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
        });
      }
      else{
        window.intlTelInput(inputElement, {
          hiddenInput: "full_phone",
          initialCountry: "auto",
          separateDialCode: true,
          geoIpLookup: this.geoIpLookup.bind(this),
          utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.19/js/utils.js",
        });
      }
 
  }

  geoIpLookup(callback) {
    fetch("https://ipinfo.io/json")
      .then((response) => response.json())
      .then((data) => {
        const countryCode = data.country ? data.country : "us";
        callback(countryCode);
      });
  }
}

export default PhoneInputHandler